import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Card, Badge, Row, Col, Table, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { fetchData } from '../helpers/api';
import { useDispatch, connect } from 'react-redux';
import { refreshAllSearches } from '../redux/actions';
import { FaRegTrashAlt } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import multisearchicon from '../assets/images/multiearchicon.png';


const AllSearchesPage = (state) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const createButtonClick = async () => {
    setIsLoading(true); // Set loading state to true 
    let url = process.env.REACT_APP_WFE_URL + "/bulksearches/create"
    const allBulkSearches = await fetchData(url, state.state.user, { jwtToken: state.state.user.jwt })
    console.log("resp is " + JSON.stringify(allBulkSearches))
    // Dispatch the action  
    dispatch(refreshAllSearches(allBulkSearches));

    // Redirect to the URL with the ID of the first entry  
    const searchId = allBulkSearches[0]._id; //uses the most recent search ID
    navigate(`/searchstep1/${searchId}`);

    setIsLoading(false); // Set loading state to false  
  };

  const deleteButtonClick = async (searchId, userId) => {
    let url = process.env.REACT_APP_WFE_URL + "/bulksearches/delete"
    const response = await fetchData(url, { "id": searchId, "userId": state.state.user.id }, { jwtToken: state.state.user.jwt })
    // // console.log("resp is " + JSON.stringify(response))
    dispatch(refreshAllSearches(response))
  };

  const handleRefresh = async () => {
    console.log("refresh!");
    setIsRefreshing(true);
    try {
      let url = process.env.REACT_APP_WFE_URL + "/database/findalldocsbyuserid"
      const allBulkSearches = await fetchData(url, {
        collection: "searches",
        userId: state.state.user.id
      }, {
        jwtToken: state.state.user.jwt
      });
      // console.log(JSON.stringify(allBulkSearches))
      dispatch(refreshAllSearches(allBulkSearches));
      setIsRefreshing(false);
    } catch (error) {
      console.error("Error during handleRefresh:", error);
      setIsRefreshing(false);
    }
  };

  const renderStatus = (searchJob) => {
    switch (searchJob.progress.status) {
      case 'Draft':
        return 'Draft';
      case 'Submitted':
        return "Awaiting Processing";
      case 'Processing':
        // Check if records length is 0  
        if (searchJob.shortlist) {
          return `${searchJob.shortlist.length} sites shortisted from ${searchJob.searchResults.length} Google results`;
        } else {
          return "Performing Searches"
        }
      case 'Completed':
        // Check if searchJob.csv exists  
        return "Completed";
      default:
        return 'Unknown Status';
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
            <MobileMenu />
          </div>
          <Row>
            <Col className="adminBackground">
              <div>
                <h1>Create a List</h1>
              </div>
              <Row>
                <Col md={7}>
                  <br></br><br></br>
                  <h5>Find and shortlist hundreds of Google results to build your prospect list.</h5>
                  <br></br><br></br>
                  <h6>How it works:</h6>
                  <p>1. Conducts multiple Google searches based on keywords you select.</p>
                  <p>2. Checks each webpage to see if it matches your needs.</p>
                  <p>3. Picks the best results for you to review.</p>
                  <br></br><br></br>

                </Col>
                <Col md={5}>
                  <img src={multisearchicon} alt="Multi Search" className="img-fluid" />
                  <p></p>

                </Col>

              </Row>
              <Row className="justify-content-center">
                <button
                  className={isLoading ? "unselected-button" : "selected-button"}
                  onClick={createButtonClick}
                  disabled={isLoading}
                  style={{ width: '30%', maxWidth: '300px' }}
                >
                  {isLoading ? <span>Creating...</span> : 'Begin Search'}
                </button>
              </Row>
              <Row>

              </Row>
              <div className="component-card">
                <h3>Past Searches</h3>
                <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
                  <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
                <br /><br />
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(state.state.searches) && state.state.searches.length > 0 ? (
                      state.state.searches.map((search, index) => (
                        <tr key={index}>
                          <td>
                            {search.progress.status === 'Draft' ? (
                              <Link to={`/searchstep1/${search._id}`} className='hyperlink'>{search.SearchQuery.topic}</Link>
                            ) : (
                              <span>{search.SearchQuery.topic}</span>
                            )}
                          </td>
                          <td>
                            {renderStatus(search)}
                          </td>
                          <td>{search.progress.status === 'Completed' && (
                            <Link to={`/searchview/${search._id}`}>
                              <Badge className="download-badge">View</Badge>
                            </Link>
                          )}
                            <Badge onClick={() => deleteButtonClick(search._id)} className="delete-badge"><FaRegTrashAlt /> Del</Badge>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No searches found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  );
};


const mapStateToProps = (state) => {
  return {
    // searchs: state.myReducer.searchs,
    // user: state.myReducer
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(AllSearchesPage);
