// Sidebar.js
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { IoCaretSharp } from "react-icons/io5";



const SearchProgress = ({ id, stage, allstate }) => {
    const navigate = useNavigate(); // Get the navigate function

    const loadStep1 = async (id) => {
        navigate(`/searchstep1/${id}`);
    };
    const loadStep2 = async (id) => {
        navigate(`/searchstep2/${id}`);
    };



    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col onClick={() => loadStep1(id)} sm={12} md={6} lg={6} className="d-none d-md-block" style={{ padding: '0px' }}><div className="bordercolumn"><div className="line"><div className={`d-none d-md-block ${stage === '1' ? 'current-circle' : 'circle'}`}><span className="circle-text">Search Criteria</span></div></div></div></Col>
                    <Col onClick={() => loadStep2(id)} sm={12} md={6} lg={6} className="d-none d-md-block" style={{ padding: '0px' }}><div className="bordercolumn"><div className="line"><div className={`d-none d-md-block ${stage === '2' ? 'current-circle' : 'circle'}`}><span className="circle-text">Review & Go</span></div></div></div></Col>
                </Row>
                <div class="col-md-3 d-block d-md-none">
                    <Row>
                    {stage === '1' ? <span class="mobilemenutextselected">Step 1: Define Search</span> : <span onClick={() => loadStep1(id)}>Step 1: Define Search</span> }
                    {stage === '2' ? <span class="mobilemenutextselected">Step 2: Review & Go</span> : <span onClick={() => loadStep2(id)}>Step 2: Review & Go</span>}
                    </Row>
                    <hr></hr>
                    <br></br>
                </div>
            </Container>
        </React.Fragment>
    );
};



const mapStateToProps = (state) => {
    return {
        allstate: state.myReducer
    };
};

export default connect(mapStateToProps)(SearchProgress);
