import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Card, Form, FormControl, FormGroup, FormLabel, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api';
import { HiRefresh } from "react-icons/hi";
import { refreshSingleSite, updateUserCredits } from '../redux/actions';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import singlesearchicon from '../assets/images/singlesearchicon.png';

const SingleSitePage = (state) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [notification, setNotification] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [validationMessage, setValidationMessage] = useState('');

    const validateURL = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol  
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name  
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address  
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path  
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string  
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator  
        return !!pattern.test(url);
    }

    const handleRefresh = async () => {
        console.log("refresh!");
        setIsRefreshing(true)
        try {
            let url = process.env.REACT_APP_WFE_URL + "/database/findalldocsbyuserid"
            const allSingleSites = await fetchData(url, {
                collection: "singlesite",
                userId: state.state.user.id
            }, {
                jwtToken: state.state.user.jwt
            });

            // console.log("Refreshing redux with " + JSON.stringify(allSingleSites));   
            dispatch(refreshSingleSite(allSingleSites));
            setIsRefreshing(false)
        } catch (error) {
            console.error("Error during handleRefresh:", error);
            setIsRefreshing(false)
        }
    };

    const handleExpandClick = (index) => {
        setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateURL(url)) {
            setValidationMessage("Please enter a valid URL");
            return;
        }
        await processSingleSite(url, specialInstructions);
    }

    const processSingleSite = async (url, specialInstructions) => {
        //update credit balance  
        let newCredits = state.state.user.credits - 1
        await dispatch(updateUserCredits(newCredits))
        let url1 = process.env.REACT_APP_WFE_URL + "/database/updateuser"
        fetchData(url1, { "_id": state.state.user.id, "credits": newCredits }, { "jwtToken": state.state.user.jwt })
        //now submit url  
        setIsProcessing(true);
        let url2 = process.env.REACT_APP_WFE_URL + "/singlesite/submit"
        const resp = await fetchData(url2, { "url": url, "userId": state.state.user.id, "language": state.state.user.language, "specialInstructions": specialInstructions }, { "jwtToken": state.state.user.jwt })
        // console.log("Resp is " + resp)  
        setIsProcessing(false);
        setValidationMessage("");
        setUrl('');
        setNotification(true);
        // Set a timeout to turn off the notification after 5 seconds   
        setTimeout(() => {
            setNotification(false);
        }, 5000);
        return;
    }

    return (
        <React.Fragment>
            <div className="container">
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className="main-content">
                    <Row>
                        <AdminHeader />
                    </Row>
                    <div class="col-md-3 d-block d-md-none">
                        <MobileMenu />
                    </div>
                    <Row>
                        <Col className="adminBackground">
                            <div>
                                <h1>Prospect a Single Site</h1>
                            </div>
                            <Row>
                                <Col md={8}>
                                    <br></br>
                                    <h5>Create all your prospecting assets.</h5><br></br>
                                    <br></br>
                                    <h6>How it works:</h6>
                                    <p>1. Enter the URL below of the organisation to target.</p>
                                    <p>2. Jumbo crawls the site and creates:</p>
                                    <li>A call script</li>
                                    <li>A prospecting email</li>
                                    <li>A short summary of the organization</li>
                                </Col>
                                <Col md={4}>
                                    <img src={singlesearchicon} alt="Multi Search" className="img-fluid" />
                                </Col>
                            </Row>
                            <div className="component-card">
                                <h3>Prospect Information</h3><br></br>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup controlId="url">
                                        <FormLabel>URL to crawl</FormLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="URL"
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                            disabled={isProcessing}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="specialInstructions">
                                        <br></br><FormLabel>Additional Instructions (optional)</FormLabel>
                                        <FormControl
                                            as="textarea"
                                            placeholder="Special Instructions"
                                            value={specialInstructions}
                                            rows={4}
                                            onChange={(e) => setSpecialInstructions(e.target.value)}
                                            disabled={isProcessing}
                                        />
                                    </FormGroup>  <br></br>
                                    Credits Required: 1 | Your Balance: {state.state.user.credits}{state.state.user.credits < 1 ? " (Insufficient credits)" : null}<br></br><br></br>
                                    <button
                                        className={
                                            isProcessing || state.state.user.credits < 1
                                                ? "unselected-button"
                                                : "selected-button"
                                        }
                                        type="submit"
                                        disabled={isProcessing || state.state.user.credits < 1}
                                    >
                                        {isProcessing ? 'Processing...' : 'Research Site'}
                                    </button>
                                    <p></p>

                                </Form>
                                {validationMessage && <div className="validation-message">{validationMessage}</div>}
                                {notification && <div className="notification"><IoCheckmarkCircleSharp /> Submitted url successfully!</div>}
                                <br></br><br></br>
                            </div>
                            <div className="component-card">
                                <h3>Your requests</h3>
                                <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
                                    <HiRefresh
                                        className={isRefreshing ? 'icon-left-spinning' : 'icon-left'}
                                    /> Refresh</span>
                                <br></br><br></br>
                                <Table bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Web address</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.state.singlesite && ( // Check if state.state.singlesite exists
                                            <>
                                                {state.state.singlesite.map((site, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr onClick={site.progress.status === "Completed" ? () => handleExpandClick(index) : null}>
                                                            <td>
                                                                <span className={expandedIndex === index ? 'regular-bold' : ''}>{site.url}</span>
                                                            </td>
                                                            <td>
                                                                <span className={expandedIndex === index ? 'regular-bold' : ''}>{site.progress.status}</span>
                                                            </td>
                                                        </tr>
                                                        {expandedIndex === index && (
                                                            <React.Fragment>
                                                                {site.result.backgroundInfo && ( // Check if backgroundInfo exists  
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <div className="preview-data-header">Short Summary</div>
                                                                            <span className="preview-generated-content">{site.result.backgroundInfo}</span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {site.result.prospectingemail && ( // Check if prospectingemail exists  
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <div className="preview-data-header">Prospecting Email</div>
                                                                            <span className="preview-generated-content">{site.result.prospectingemail}</span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {site.result.callscript && ( // Check if callscript exists  
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <div className="preview-data-header">Call Script</div>
                                                                            <span className="preview-generated-content">{site.result.callscript}</span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {site.result.voicemailMessage && ( // Check if voicemailMessage exists  
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <div className="preview-data-header">Voicemail Message</div>
                                                                            <span className="preview-generated-content">{site.result.voicemailMessage}</span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.myReducer
    };
};

export default connect(mapStateToProps)(SingleSitePage);  
