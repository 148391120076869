import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Card, Badge, Row, Col, Table, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { fetchData } from '../helpers/api';
import { useDispatch, connect } from 'react-redux';
import { refreshJobs } from '../redux/actions';
import { IoIosCloudDownload } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";



const JobsPage = (state) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const createButtonClick = async () => {
    setIsLoading(true); // Set loading state to true 
    let url = process.env.REACT_APP_WFE_URL + "/jobs/createjob"
    const response = await fetchData(url, state.state.user, { jwtToken: state.state.user.jwt })
    // console.log("resp is " + JSON.stringify(response))
    // Dispatch the action  
    dispatch(refreshJobs(response));

    // Redirect to the URL with the ID of the first entry  
    if (response.length > 0) {
      const jobId = response[0]._id;
      navigate(`/builder/${jobId}`);
    }
    setIsLoading(false); // Set loading state to false  
  };

  const deleteButtonClick = async (jobId, userId) => {
    let url = process.env.REACT_APP_WFE_URL + "/jobs/deletejob"
    const response = await fetchData(url, { "id": jobId, "userId": state.state.user.id }, { jwtToken: state.state.user.jwt })
    // // console.log("resp is " + JSON.stringify(response))
    dispatch(refreshJobs(response))
  };
  const handleRefresh = async () => {
    console.log("refresh!");
    setIsRefreshing(true);
    try {
      let url = process.env.REACT_APP_WFE_URL + "/database/findalldocsbyuserid"
      const allListUploads = await fetchData(url, {
        collection: "jobs",
        userId: state.state.user.id
      }, {
        jwtToken: state.state.user.jwt
      });
      console.log(JSON.stringify(allListUploads))
      dispatch(refreshJobs(allListUploads));
      setIsRefreshing(false);
    } catch (error) {
      console.error("Error during handleRefresh:", error);
      setIsRefreshing(false);
    }
  };

  const renderStatus = (searchJob) => {  
    switch (searchJob.progress.status) {  
      case 'Draft':  
        return 'Draft';  
      case 'Preparing Download':  
        return 'Completing...';  
      case 'Submitted':  
        return 'Awaiting Processing';  
      case 'Processing':  
        // Check if records length is 0  
        if (searchJob.records.length === 0) {  
          return 'Performing Searches';  
        } else {  
          return `${searchJob.records.length} of ${searchJob.progress.numRows} Processed`;  
        }  
      case 'Completed':  
        // Check if searchJob.csv exists  
        if (searchJob.csv) {  
          return (  
            <Badge  
              className="download-badge"  
              onClick={() => {  
                const link = document.createElement('a');  
                link.href = `https://jummbo.blob.core.windows.net/downloads/${searchJob.csv}`;  
                link.download = "jummbo.csv"; // Optional: This sets the downloaded filename  
                document.body.appendChild(link);  
                link.click();  
                document.body.removeChild(link);  
              }}  
            >  
              <IoIosCloudDownload /> Download  
            </Badge>  
          );  
        } else {  
          return 'Download link not available';  
        }  
      default:  
        return 'Unknown Status';  
    }  
  };  

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
            <MobileMenu />
          </div>
          <Row>
            <Col className="adminBackground">
              <div>
                <h1>Build a Prospect List</h1>
              </div>
              <Row>
                <Col><br></br><br></br>
                  <p>Have you found an industry you'd like to prospect?<br></br></p>
                  <p>Follow our wizard to define your search criteria - we'll then do the research, and create for you:</p>
                  <li>A list of target organisations, with their:</li>
                  <li>Social media links (Insta, Facebook, LinkedIn & YouTube)</li>
                  <li>Ready to use prospecting emails</li>
                  <li>Call ecripts</li>
                  <li>Short summary of the organisation</li>
                  <li>Selection of relevant fields</li>
                </Col>
                <Col>
                  <Card className="card-dashboard">
                    <Card.Body>
                      <Card.Title><span className='generate-text-selected'><FaCheckCircle /></span> Your Business Info</Card.Title>
                      <Card.Text>
                        You've already told us about your business, so each call script and prospecting email will be highly targeted.
                        <br></br><br></br>
                        <Link to={`/settings`} className='hyperlink'>Edit</Link>
                        <br></br>
                      </Card.Text>
                      <br></br>
                      <button
                        className={isLoading ? "unselected-button" : "selected-button"}
                        onClick={createButtonClick}
                        disabled={isLoading}
                      >
                        {isLoading ? <span>Creating...</span> : 'Create a new list'}
                      </button>
                      <p></p>
                    </Card.Body>
                  </Card>

                </Col>
              </Row>
              <div className="component-card">
                <h3>Your requests</h3>
                <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
                  <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
                <br /><br />
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(state.state.jobs) && state.state.jobs.length > 0 ? (
                      state.state.jobs.map((job, index) => (
                        <tr key={index}>
                          <td>
                            {job.progress.status === 'Draft' ? (
                              <Link to={`/builder/${job._id}`} className='hyperlink'>{job.SearchQuery.topic}</Link>
                            ) : (
                              <span>{job.SearchQuery.topic}</span>
                            )}
                          </td>
                          <td>
                            {renderStatus(job)}
                          </td>
                          <td>
                            <Badge onClick={() => deleteButtonClick(job._id)} className="delete-badge"><FaRegTrashAlt /> Del</Badge>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No jobs available.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  );
};


const mapStateToProps = (state) => {
  return {
    // jobs: state.myReducer.jobs,
    // user: state.myReducer
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(JobsPage);
