import React, { useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { Badge, Row, Col, Alert } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { Table, Form } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { FaAnglesLeft } from "react-icons/fa6";
import { setBulkURLs } from '../redux/actions';

const SearchViewPage = (state) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate(); // Get the navigate function

  const [checkedRows, setCheckedRows] = useState({});
  const [notification, setNotification] = useState({ message: '', visible: false });

  const handleCheckboxChange = (index, url) => {
    setCheckedRows(prevState => {
      const newCheckedRows = { ...prevState, [index]: !prevState[index] };
      if (newCheckedRows[index]) {
        // Add URL to bulkurls if checked
        dispatch(setBulkURLs([...state.state.bulkurls, url]));
      } else {
        // Remove URL from bulkurls if unchecked
        dispatch(setBulkURLs(state.state.bulkurls.filter(item => item !== url)));
      }
      return newCheckedRows;
    });
  };

  const truncateUrl = (url) => {
    // Remove the protocol
    let truncatedUrl = url.replace(/^https?:\/\//, '');
    // Find the first slash after the protocol
    const firstSlashIndex = truncatedUrl.indexOf('/');
    if (firstSlashIndex !== -1) {
      truncatedUrl = truncatedUrl.substring(0, firstSlashIndex);
    }
    // Truncate to 20 characters
    return truncatedUrl.length > 20 ? truncatedUrl.substring(0, 20) + '...' : truncatedUrl;
  };

  return (
    <React.Fragment>
      <div className="container">
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <Row>
            <Col className="adminBackground">
              <NavLink to="/allsearches">
                <h6><FaAnglesLeft /> Back</h6>
              </NavLink>
              {
                state.state.searches.map((search, index) => (
                  <React.Fragment>

                    {search._id === id ? (
                      <React.Fragment>
                        <div className="component-card">
                          <h6>Viewing results for:</h6>
                          <h3>{search.SearchQuery.topic}</h3>
                          <br></br><br></br>
                          <h4>{search.searchResults.filter(result => result.isRelevant === true).length} Shortlisted Sites</h4>
                          <h6>(from {search.searchResults.length} Google results)</h6>
                          <br></br><br></br>
                          <Row>
                            {notification.visible && (
                              <Alert variant="success">
                                {notification.message}
                              </Alert>
                            )}
                            <Table responsive bordered hover>
                              <thead>
                                <tr>
                                  <th>Site Tested</th>
                                  <th>Title</th>
                                  <th>Snippet</th>
                                  <th>
                                    {state.state.bulkurls ? state.state.bulkurls.length : 0} selected for prospecting
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {search.searchResults.filter(result => result.isRelevant === true).map((result, idx) => (
                                  <tr key={idx} onClick={() => handleCheckboxChange(idx, result.url)}>
                                    <td style={{ whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '0.8em', padding: '0.1rem 0.5rem' }}>
                                      <a href={result.url} target="_blank" rel="noopener noreferrer">
                                        {truncateUrl(result.url, 25)}
                                      </a>
                                    </td>
                                    <td style={{ whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '0.8em', padding: '0.1rem 0.5rem' }}>{result.title}</td>
                                    <td style={{ whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '0.8em', padding: '0.1rem 0.5rem' }}>{result.snippet}</td>
                                    <td style={{ padding: '0.1rem 0.5rem' }}>
                                      <Form.Check
                                        type="checkbox"
                                        checked={checkedRows[idx] || false}
                                        onChange={() => handleCheckboxChange(idx, result.url)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Row>

                        </div>

                      </React.Fragment>

                      //don't show anything if the search id doesn't match
                    ) : null}
                  </React.Fragment>
                ))}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(SearchViewPage);