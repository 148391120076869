import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Badge, Row, Col } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api'; // Import the fetchData function
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import SearchProgress from '../components/SearchProgress';
import { refreshAllSearches } from '../redux/actions';
import { ImCross } from "react-icons/im";
import { FaAnglesRight } from "react-icons/fa6";

const SearchStep1Page = (state) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate(); // Get the navigate function
  const [inputValue, setInputValue] = useState(''); // store the value of the searchbox
  const [isSearching, setIsSearching] = useState(false); // store the state of the search button


  // handle when something is typed in the searchbox
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  // handle when the search button is clicked
  const handleButtonClick = async (event) => {
    setIsSearching(true);
    try {
      let url1 = process.env.REACT_APP_WFE_URL + "/definesearch/initialQuery"
      const resp = await fetchData(url1, { phrase: inputValue }, { jwtToken: state.state.user.jwt });
      const updatedSearches = await Promise.all(state.state.searches.map(async (search) => {
        if (search._id === id) {
          // Update the job  
          const updatedSearch = {
            ...search,
            SearchQuery: {
              topic: search.SearchQuery.topic,
              keywords: resp.keywords,
              locations: resp.locations,
              searchregion: search.SearchQuery.searchregion,
              selectedkeywords: search.SearchQuery.selectedkeywords,
              selectedlocations: search.SearchQuery.selectedlocations
            },
          };

          // Second API call to update SearchQuery.topic  
          let url2 = process.env.REACT_APP_WFE_URL + "/definesearch/setTopic"
          const topicUpdateResp = await fetchData(url2, updatedSearch.SearchQuery, { jwtToken: state.state.user.jwt });

          // // Update the topic with the result from the second API call  
          updatedSearch.SearchQuery.topic = topicUpdateResp.description;

          return updatedSearch;
        }
        // Return the search as-is if it doesn't match the ID to send to the reducer  
        return search;
      }));

      // console.log("updatedJobs is now: " + JSON.stringify(updatedJobs));
      dispatch(refreshAllSearches(updatedSearches));
      setIsSearching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error (e.g., show an error message to the user)  
      setIsSearching(false);
    }
  };



  const handleNewSelectedKeyword = async (thisKeyword, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
    const updatedUnselectedKeywords = [...unselectedKeywords];
    const updatedSelectedKeywords = [...selectedKeywords];
    const indexToRemove = updatedUnselectedKeywords.indexOf(thisKeyword); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedUnselectedKeywords.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    updatedSelectedKeywords.push(thisKeyword)
    const us = state.state.searches.map((search) => {
      if (search._id === thisSearchId) {
        return {
          ...search,
          SearchQuery: {
            topic: search.SearchQuery.topic,
            keywords: updatedUnselectedKeywords,
            locations: unselectedLocations,
            searchregion: search.SearchQuery.searchregion,
            selectedkeywords: updatedSelectedKeywords,
            selectedlocations: selectedLocations,
          },
        };
      }
      return search;
    });
    dispatch(refreshAllSearches(us));
  };

  // const removeSelectedKeyword = async (thisKeyword, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
  //   console.log("event is: " + thisKeyword);
  // }

  const removeSelectedKeyword = async (thisKeyword, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
    const updatedSelectedKeywords = [...selectedKeywords];
    const updatedUnselectedKeywords = [...unselectedKeywords];

    // Take selected keyword out of the original array
    const indexToRemove = updatedSelectedKeywords.indexOf(thisKeyword); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedSelectedKeywords.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // console.log("updatedUnselectedKeywordsis " + updatedUnselectedKeywords)
    // // now put the selected keyword into the selected keywords array
    updatedUnselectedKeywords.push(thisKeyword)

    // // Find the search with the specified ID
    const us = state.state.searches.map((search) => {
      if (search._id === thisSearchId) {
        // Create a new object with updated properties
        return {
          ...search,
          SearchQuery: {
            topic: search.SearchQuery.topic,
            searchregion: search.SearchQuery.searchregion,
            keywords: updatedUnselectedKeywords,
            locations: unselectedLocations,
            selectedkeywords: updatedSelectedKeywords,
            selectedlocations: selectedLocations,
          },
        };
      }
      return search; // No changes for other jobs
    });
    dispatch(refreshAllSearches(us));
  };


  const handleNewSelectedLocation = async (thisLocation, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
    //add selected location into "selected locations" array

    // Create new arrays for selected and unselected keywords
    const updatedUnselectedLocations = [...unselectedLocations];
    const updatedSelectedLocations = [...selectedLocations];

    //  now take selected location out of the original array
    const indexToRemove = updatedUnselectedLocations.indexOf(thisLocation); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedUnselectedLocations.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // now put the selected location into the selected keywords array
    updatedSelectedLocations.push(thisLocation)

    // Find the search with the specified ID
    const us = state.state.searches.map((search) => {
      if (search._id === thisSearchId) {
        // Create a new object with updated properties
        return {
          ...search,
          SearchQuery: {
            topic: search.SearchQuery.topic,
            searchregion: search.SearchQuery.searchregion,
            keywords: unselectedKeywords,
            locations: updatedUnselectedLocations,
            selectedkeywords: selectedKeywords,
            selectedlocations: updatedSelectedLocations,
          },
        };
      }
      return search; // No changes for other searches
    });
    dispatch(refreshAllSearches(us));
    //   checkIfReadyForStep2(selectedKeywords, updatedSelectedLocations)
  };

  // const removeSelectedLocation = async (thisLocation, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
  //   console.log("event is: " + thisLocation);
  // }

  const removeSelectedLocation = async (thisLocation, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisSearchId) => {
    //add selected location into "selected locations" array

    // Create new arrays for selected and unselected keywords
    const updatedUnselectedLocations = [...unselectedLocations];
    const updatedSelectedLocations = [...selectedLocations];

    // //  now take selected location out of the original array
    const indexToRemove = updatedSelectedLocations.indexOf(thisLocation); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedSelectedLocations.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // // now put the selected location into the selected keywords array
    updatedUnselectedLocations.push(thisLocation)

    // // Find the search with the specified ID
    const us = state.state.searches.map((search) => {
      if (search._id === thisSearchId) {
        // Create a new object with updated properties
        return {
          ...search,
          SearchQuery: {
            topic: search.SearchQuery.topic,
            searchregion: search.SearchQuery.searchregion,
            keywords: unselectedKeywords,
            locations: updatedUnselectedLocations,
            selectedkeywords: selectedKeywords,
            selectedlocations: updatedSelectedLocations,
          },
        };
      }
      return search; // No changes for other jobs
    });
    dispatch(refreshAllSearches(us));
  };

  const proceedToStep2 = async (searchId) => {
    navigate(`/searchstep2/${searchId}`);
  }



  return (
    < React.Fragment >
      <div className="container">
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <Row>
            <Col className="adminBackground">
              <SearchProgress id={id} stage='1' />
              {
                state.state.searches.map((search, index) => (
                  <React.Fragment>

                    {search._id === id ? (
                      <React.Fragment>

                        <div className="component-card">
                          <h3>{search.SearchQuery.topic}</h3>
                          <Row>
                            <div>What businesses are you searching for? Include some relevant <strong>search terms</strong> and a <strong>location</strong>.</div>
                            <div>
                              <textarea
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="eg: residential electricians in greater London UK"
                                className="custom-textarea"
                                rows={4}
                              />
                            </div>
                            <span>
                              <button className={"selected-button"} disabled={isSearching} onClick={() => handleButtonClick(inputValue)}>{isSearching ? 'Finding...' : 'Find Keywords'}</button>
                            </span>
                          </Row>
                          {search.SearchQuery.keywords.length > 0 ? (
                            <Row>
                              <Col xs={12} md={8}>
                                <br></br>
                                <div>
                                  <h6>Select relevant industry niches:</h6>
                                  {search.SearchQuery.keywords.map((keyword, index) => (
                                    <React.Fragment>

                                      <span key={index}>
                                        {/* <Fade in={open}> */}
                                        <Badge onClick={() => handleNewSelectedKeyword(keyword, search.SearchQuery.keywords, search.SearchQuery.selectedkeywords, search.SearchQuery.locations, search.SearchQuery.selectedlocations, id)} className="unselected-badge">{keyword}</Badge>
                                        {/* </Fade> */}
                                      </span>

                                    </React.Fragment>
                                  ))}
                                </div>
                                <div>
                                  <br></br>
                                  <h6>Select relevant locations:</h6>
                                  {search.SearchQuery.locations.length > 0 ? (
                                    <React.Fragment>
                                      {
                                        search.SearchQuery.locations.map((location, index) => (
                                          <span key={index}><Badge onClick={() => handleNewSelectedLocation(location, search.SearchQuery.keywords, search.SearchQuery.selectedkeywords, search.SearchQuery.locations, search.SearchQuery.selectedlocations, id)} className="unselected-badge">{location}</Badge></span>
                                        ))
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <span>Add a location or area to your search term above.</span>
                                  )
                                  }
                                </div>
                              </Col>
                              <Col xs={6} md={4}>
                                <br></br>
                                <h5>Selected Keywords</h5>
                                {search.SearchQuery.selectedkeywords && search.SearchQuery.selectedkeywords.length > 0 ? (
                                  <React.Fragment>
                                    {
                                      search.SearchQuery.selectedkeywords.map((selectedkeyword, index) => (
                                        <Badge key={index} onClick={() => removeSelectedKeyword(selectedkeyword, search.SearchQuery.keywords, search.SearchQuery.selectedkeywords, search.SearchQuery.locations, search.SearchQuery.selectedlocations, id)} className="selected-badge">{selectedkeyword}  <ImCross size={"0.9em"} /></Badge>
                                      ))
                                    }
                                  </React.Fragment>
                                ) : (
                                  null
                                )
                                }
                                <br></br><br></br>
                                <h5>Selected Locations</h5>
                                {search.SearchQuery.selectedlocations && search.SearchQuery.selectedlocations.length > 0 ? (
                                  <React.Fragment>
                                    {
                                      search.SearchQuery.selectedlocations.map((selectedlocation, index) => (
                                        <Badge key={index} onClick={() => removeSelectedLocation(selectedlocation, search.SearchQuery.keywords, search.SearchQuery.selectedkeywords, search.SearchQuery.locations, search.SearchQuery.selectedlocations, id)} className="selected-badge">{selectedlocation} <ImCross size={"0.9em"} /></Badge>
                                      ))
                                    }
                                  </React.Fragment>
                                ) : (
                                  null
                                )
                                }
                                <br></br><br></br>
                                {search.SearchQuery.selectedlocations?.length > 0 &&
                                  search.SearchQuery.selectedkeywords?.length > 0 && (
                                    <button className={"selected-button"} onClick={() => proceedToStep2(id)}>
                                      Continue<FaAnglesRight />
                                    </button>
                                  )}
                              </Col>
                            </Row>

                          ) : null}
                        </div>

                      </React.Fragment>

                      //don't show anything if the search id doesn't match
                    ) : null}
                  </React.Fragment>
                ))}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  );
};



const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(SearchStep1Page);