import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { FaSave, FaTrash, FaArrowUp, FaArrowDown, FaPhone, FaLinkedin } from 'react-icons/fa';
import { GrTest } from "react-icons/gr";
import { TbPlaneDeparture } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api';
import { refreshCadences, refreshReadOnlyCadences } from '../redux/actions';

const CadenceEditPage = (allstate) => {
    const { id } = useParams(); // Get the id from the URL parameters  
    const dispatch = useDispatch();
    const [loadingIndex, setLoadingIndex] = useState(false);
    const [data, setData] = useState({
        _id: id,
        name: "",
        description: "",
        youTubeUrl: "",
        userId: allstate.allstate.user.id,
        sampleProspectBio: "",
        sampleSellerBio: "",
        lastModified: Date.now(),
        steps: []
    });
    const [sampleProspectBio, setsampleProspectBio] = useState(data.sampleProspectBio); // New state for sampleProspectBio  
    const [sampleSellerBio, setSampleSellerBio] = useState(""); // New state for sample seller bio  
    const [showExample, setShowExample] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    // New useEffect to preload the cost data  
    useEffect(() => {
        if (allstate.allstate.cadences && allstate.allstate.cadences.length > 0) {
            const initialCadence = allstate.allstate.cadences[0];
            setData(prevData => ({
                ...prevData,
                name: initialCadence.name || "",
                description: initialCadence.description || "",  
                youTubeUrl: initialCadence.youTubeUrl || "",  
                furtherInfoUrl: initialCadence.furtherInfoUrl || "",  
                sampleProspectBio: initialCadence.sampleProspectBio,
                sampleSellerBio: initialCadence.sampleSellerBio,
                steps: initialCadence.steps || [] // Assuming cadence is an array  
            }));
        }
    }, [allstate.allstate.cadences]); // Dependency array  

    const toggleExample = () => {
        setShowExample(!showExample);
    };

    const getExampleText = (type) => {
        switch (type) {
            case 'email':
                return `Write "Subject:" then enter a concise and intriguing subject that gains their interest.<br>  
                        Write a customer-ready email containing the following items in order:<br>  
                        1. explain what you know of the business from your research, act as if you have known about them for a long time<br>  
                        2. mention a common challenge you see companies like this face that you can help them with<br>  
                        3. state your purpose and value proposition in a positioning statement.<br>  
                        4. offer a soft close and next steps to invite further interaction. Suggest a timeframe reasonable for the industry this prospect is in.<br>  
                        Each item MUST be a new paragraph.<br>  
                        Keep the total email under 150 words.`;
            case 'phone':
                return `Structure the call script in the follow manner:<br>  
                    INTRO - greet the prospect, introduce yourself and your company.<br>  
                    TRANSITION - smoothly segue into the purpose of the call.<br>  
                    VALUE PROPOSITION - states the benefits and solutions your product or service offers for the prospect's needs.<br>  
                    QUALIFYING QUESTION - show your expertise with open-ended question to understand your prospect's challenges and gather relevant information.<br>  
                    NEXT STEPS - proposes a clear next step based on the prospect's interest, such as scheduling a demo or sending more information.<br>  
                    CLOSE - thanks the prospect for their time and leaves the door open for future communication.<br>  
                    Always write the script with 2 people: "Prospect" (the person calling) and "You" the caller.`;
            case 'linkedin':
                return `Write a succinct and compelling DM I can send on LinkedIn to a prospect based on how I can help their company given the work we both do. Tell them they can expect to hear from me soon via phone.`;
            default:
                return ''; // Return an empty string if type is not recognized  
        }
    };

    function generateHexCode() {
        const randomColor = Math.floor(Math.random() * 65536).toString(16); // 65536 is 0x10000 in decimal  
        return `#${randomColor.padStart(4, '0')}`;
    }

    const addNewItem = (position) => {
        var stepId = id + generateHexCode();
        const lastItemDaysDelay = data.steps.length > 0 ? data.steps[data.steps.length - 1].daysDelay : 0; // Get the daysDelay of the last item or default to 0  

        const newItem = {
            stepid: stepId,
            name: 'Email', //defaults as Email as the activity
            type: 'email',
            position: position,
            prompt: '',
            requireUserContent: false,
            userContent: '',
            textBoxTitle: '',
            textTip: '',
            testRequireUserContent: '',
            isExpanded: false,
            testResponse: null,
            daysDelay: lastItemDaysDelay, // Use the last item's daysDelay  
            isPromptRequired: true // Default checkbox value  
        };
        const updatedCadence = [...data.steps.slice(0, position), newItem, ...data.steps.slice(position)];
        setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
    };

    const editItem = (index) => {
        const updatedCadence = data.steps.map((item, idx) => ({
            ...item,
            isExpanded: idx === index ? !item.isExpanded : false // Toggle current index and collapse others  
        }));
        setData({ ...data, steps: updatedCadence });
    };

    const handleInputChange = (index, field, value) => {
        const updatedCadence = data.steps.map((step, idx) => 
            idx === index ? { ...step, [field]: value } : step
        );
        setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
    };

    const changeType = (index, newType) => {
        const typeToNameMap = {
            phone: 'Call Script',
            linkedin: 'LinkedIn Message',
            email: 'Email'
        };
        const updatedCadence = data.steps.map((item, idx) => 
            idx === index ? { ...item, type: newType, name: typeToNameMap[newType] } : item
        );
        setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
    };
    

    const moveItem = (index, direction) => {
        const updatedCadence = [...data.steps];
        const [movedItem] = updatedCadence.splice(index, 1);
        const newPosition = index + direction;
        updatedCadence.splice(newPosition, 0, movedItem);
        setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
    };

    const deleteItem = (index) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedCadence = data.steps.filter((_, i) => i !== index);
            setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
        }
    };

    const onSave = async () => {  
        const finalData = {  
            ...data  
        };  
        console.log(JSON.stringify(finalData, null, 2));  
        let url = process.env.REACT_APP_WFE_URL + "/database/updatecadence";  
        await fetchData(url, finalData, { "jwtToken": allstate.allstate.user.jwt });  
        dispatch(refreshCadences([finalData]));  
      
        // Now modify the existing appCadences state so that an admin can view the cadence as their customer will see when they click over  
        const allAppCadences = allstate.allstate.readOnlyCadences;  
      
        // Iterate over the allAppCadences array looking for a match where _id matches finalData._id  
        const updatedCadences = allAppCadences.map(cadence => {  
            if (cadence._id === finalData._id) {  
                // When you find a match, replace that array item with finalData  
                return finalData;  
            }  
            return cadence;  
        });  
      
        // Log allAppCadences  
        console.log(JSON.stringify(updatedCadences, null, 2));  
        dispatch(refreshReadOnlyCadences(updatedCadences))
        setIsEditing(false);  
    };  

    const toggleTestResponseExpansion = (index) => {
        const updatedCadence = [...data.steps];
        updatedCadence[index].isTestResponseExpanded = !updatedCadence[index].isTestResponseExpanded; // Toggle expanded state  
        setData({ ...data, steps: updatedCadence });
    };

    const onTest = async (item) => {
        setLoadingIndex(true); // Set loading index to disable the button  
        try {
            // Simulating a server call with a timeout  
            await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate 2 seconds wait  
            // Simulate some test response logic  
            let payload = {
                prompt: item.prompt,
                sampleSellerBio: data.sampleSellerBio,
                sampleProspectBio: data.sampleProspectBio,
                textBoxTitle: item.textBoxTitle,
                testRequireUserContent: item.testRequireUserContent
            };
            // Create a copy of the current cadence  
            const updatedCadence = [...data.steps];
            const testStepReply = await fetchData(process.env.REACT_APP_WFE_URL + "/ai/teststep", payload, { "jwtToken": allstate.allstate.user.jwt });
            console.log("test step reply is" + (testStepReply));
            // Update the testResponse for the item at the correct index  
            const newSteps = updatedCadence.map((step, idx) => 
                idx === item.index ? { ...step, testResponse: testStepReply.body } : step
            );
            // Update the state with the new cadence  
            setData({ ...data, steps: newSteps, lastModified: Date.now() });
        } finally {
            setLoadingIndex(false); // Reset loading index after completion  
        }
    };

    const handleTestRequireUserContentChange = (index, value) => {
        const updatedCadence = [...data.steps];
        updatedCadence[index].testRequireUserContent = value;
        setData({ ...data, steps: updatedCadence, lastModified: Date.now() });
    };

    const handlesampleProspectBioChange = (e) => {
        const value = e.target.value;
        if (value.length <= 700) {
            setsampleProspectBio(value);
            setData({ ...data, sampleProspectBio: value });
        }
    };

    const handleSampleSellerBioChange = (e) => {
        const value = e.target.value;
        if (value.length <= 800) {
            setSampleSellerBio(value);
            setData({ ...data, sampleSellerBio: value });
        }
    };

    const formatTestResponse = (response) => {
        return response.replace(/\n/g, '<br />');
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className="main-content">
                    <Row>
                        <AdminHeader />
                    </Row>
                    <div className="col-md-3 d-block d-md-none">
                        <MobileMenu />
                    </div>
                    <Row>
                    <h1>Edit Cadence</h1>
                        <Col className="adminBackground">
                            {isEditing ? (
                                <>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        value={data.name}
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                        placeholder="Enter cadence name"
                                        style={{ fontSize: '1.75rem', fontWeight: 'bold' }} // h3 style
                                    />
                                </Form.Group>
                                <Form.Group>  
                                        <Form.Label>Description</Form.Label>  
                                        <Form.Control  
                                            as="textarea"  
                                            rows={3}  
                                            value={data.description}  
                                            onChange={(e) => setData({ ...data, description: e.target.value })}  
                                            placeholder="Enter description"  
                                        />  
                                    </Form.Group>  
                                    <Form.Group>  
                                        <Form.Label>YouTube Video Id</Form.Label>
                                        <p>Just the video embed id, eg 9SrZVNGHiJ0?si=h7K8SiPTFQtJ8ecd </p>
                                        <Form.Control  
                                            type="url"  
                                            value={data.youTubeUrl}  
                                            onChange={(e) => setData({ ...data, youTubeUrl: e.target.value })}  
                                            placeholder="Enter YouTube URL"  
                                        />  
                                    </Form.Group>  
                                    <Form.Group>  
                                        <Form.Label>Further Info URL</Form.Label>  
                                        <Form.Control  
                                            type="url"  
                                            value={data.furtherInfoUrl}  
                                            onChange={(e) => setData({ ...data, furtherInfoUrl: e.target.value })}  
                                            placeholder="Enter further info URL"  
                                        />  
                                    </Form.Group>  
                                </>
                            ) : (
                                <h3 style={{ display: 'flex', alignItems: 'center' }}>
                                    {data.name}
                                    <FaEdit onClick={() => setIsEditing(true)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                </h3>
                            )}
                            <br></br>
                            <div>
                                {data.steps.map((item, index) => (
                                    <Card key={index} className="card-dashboard">
                                        <Card.Body>
                                            <Row>
                                                <React.Fragment>
                                                    <Col
                                                        xs={item.isExpanded ? 8 : 12}
                                                        onClick={() => editItem(index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontSize: '1.4em',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <span style={{ marginRight: '8px' }}>
                                                            {item.type === 'linkedin' ? (
                                                                <FaLinkedin color="#0D4D3D" size={26} />
                                                            ) : item.type === 'email' ? (
                                                                <MdOutlineMailOutline color="#0D4D3D" size={26} />
                                                            ) : (
                                                                <FaPhone color="#0D4D3D" size={26} />
                                                            )}
                                                        </span>
                                                        <span style={{ flex: 1 }}>
                                                            {item.name}
                                                        </span>
                                                        {/* Show Day value only when item is not expanded */}
                                                        {!item.isExpanded && (
                                                            <span style={{ marginLeft: 'auto', textTransform: 'uppercase', fontSize: '0.7em' }}>
                                                                Day {item.daysDelay}
                                                            </span>
                                                        )}
                                                    </Col>
                                                    {item.isExpanded && (
                                                        <>
                                                            <Col xs={4} className="text-right">
                                                                {item.type !== 'linkedin' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'linkedin')}>
                                                                        <FaLinkedin color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                {item.type !== 'email' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'email')}>
                                                                        <MdOutlineMailOutline color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                {item.type !== 'phone' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'phone')}>
                                                                        <FaPhone color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                <span style={{ margin: '0 8px' }}>|</span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => moveItem(index, -1)} disabled={index === 0}>
                                                                    <FaArrowUp color="#0D4D3D" size={24} />
                                                                </span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => moveItem(index, 1)} disabled={index === data.steps.length - 1}>
                                                                    <FaArrowDown color="#0D4D3D" size={24} />
                                                                </span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => deleteItem(index)}>
                                                                    <FaTrash color="#D46728" size={24} />
                                                                </span>
                                                            </Col>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            </Row>
                                            {item.isExpanded && (
                                                <div>
                                                    <div className="divider"></div>
                                                    <Form.Group>
                                                        <Form.Label>Cadence Day</Form.Label>
                                                        <div className="form-tip">The day of the cadence you recommend this step is actioned.</div>
                                                        <Form.Control
                                                            as="select"
                                                            value={item.daysDelay}
                                                            onChange={(e) => handleInputChange(index, 'daysDelay', parseInt(e.target.value))}
                                                        >
                                                            {[...Array(31).keys()].map((day) => (
                                                                <option key={day} value={day}>Day {day}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <br></br>
                                                    <Form.Group>
                                                        <Form.Check
                                                            id={`promptRequired-${index}`} // Unique ID for the checkbox  
                                                            type="checkbox"
                                                            label="Is Prompt Required?"
                                                            checked={item.isPromptRequired}
                                                            onChange={(e) => handleInputChange(index, 'isPromptRequired', e.target.checked)}
                                                        />
                                                        <div className="form-tip">Check if a prompt is required for this step.</div>
                                                    </Form.Group>
                                                    {item.isPromptRequired && ( // Conditional rendering  
                                                        <>
                                                            <Form.Group>
                                                                <Form.Label>Prompt</Form.Label>
                                                                <div className="form-tip">Write complete instructions for Jummbo here to generate this {item.name.toLowerCase()}.</div>
                                                                <div className="form-tip">Seller and prospect bio's will be added automatically.</div>
                                                                <div>
                                                                    <span
                                                                        className='hyperlink'
                                                                        onClick={toggleExample}
                                                                    >
                                                                        {showExample ? 'Hide Example' : 'View Example'}
                                                                        <br></br><br></br>
                                                                    </span>
                                                                </div>
                                                                {showExample && (
                                                                    <div className="example-text">
                                                                        <p>
                                                                            <div className="example-text" dangerouslySetInnerHTML={{ __html: getExampleText(item.type) }} />
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={9}
                                                                    value={item.prompt}
                                                                    onChange={(e) => handleInputChange(index, 'prompt', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    )}
                                                    <br></br>
                                                    <Form.Group>
                                                        <Form.Check
                                                            id={`requireUserContent-${index}`} // Unique ID for the checkbox  
                                                            type="checkbox"
                                                            label="Require User Content?"
                                                            checked={item.requireUserContent}
                                                            onChange={(e) => handleInputChange(index, 'requireUserContent', e.target.checked)}
                                                        />
                                                        <div className="form-tip">Tick if the user needs to provide additional information to the prompt, for example details of a case study which is to be referred to in this step. They can provide this information on their "Settings" page.</div>
                                                    </Form.Group>
                                                    {item.requireUserContent && ( // Conditional rendering  
                                                        <>
                                                            <br></br>
                                                            <Form.Group>
                                                                <Form.Label>Text Box Title</Form.Label>
                                                                <div className="form-tip">The title which your user will see in their settings page, for example "Case Study".</div>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={item.textBoxTitle}
                                                                    onChange={(e) => handleInputChange(index, 'textBoxTitle', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>Text Tip</Form.Label>
                                                                <div className="form-tip">Provide a short text tip to help the user complete this. For example you could write, "provide a paragraph or two of text describing the case study which Jummbo will refer to in this step".</div>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={item.textTip}
                                                                    onChange={(e) => handleInputChange(index, 'textTip', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            {item.requireUserContent && (
                                                                <>
                                                                    <br></br>
                                                                    <Form.Group>
                                                                        <Form.Label>Test Require User Content</Form.Label>
                                                                        <div className="form-tip">Used for your testing only - include sample content, such as an example case study, to help validate the quality of this {item.name.toLowerCase()}.</div>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={9}
                                                                            value={item.testRequireUserContent} // Bind to the new state  
                                                                            onChange={(e) => handleTestRequireUserContentChange(index, e.target.value)} // Call the new handler  
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="divider"></div>
                                                    {item.testResponse && (
                                                        <>
                                                            <h5>Test Response</h5>
                                                            <div className="form-tip">
                                                                {item.isTestResponseExpanded
                                                                    ? <div dangerouslySetInnerHTML={{ __html: formatTestResponse(item.testResponse) }} />
                                                                    : item.testResponse.length > 200
                                                                        ? <div dangerouslySetInnerHTML={{ __html: formatTestResponse(item.testResponse.substring(0, 200) + '...') }} />
                                                                        : <div dangerouslySetInnerHTML={{ __html: formatTestResponse(item.testResponse) }} />
                                                                }
                                                            </div>
                                                            {item.testResponse.length > 200 && (
                                                                <span
                                                                    onClick={() => toggleTestResponseExpansion(index)}
                                                                    className='hyperlink'
                                                                >
                                                                    {item.isTestResponseExpanded ? ' Hide' : ' Expand'}
                                                                </span>
                                                            )}
                                                            <br /><br />
                                                        </>
                                                    )}
                                                    <button
                                                        className={loadingIndex ? "unselected-button" : "selected-button"} // Change class based on loading  
                                                        type="submit"
                                                        style={{ margin: '0 8px' }}
                                                        disabled={loadingIndex === item.index} // Disable button while loading  
                                                        onClick={() => onTest({ ...item, index})}
                                                    >
                                                        <TbPlaneDeparture /> {loadingIndex ? "Testing" : "Test"} {item.name}
                                                    </button>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>
                                ))}
                                <div
                                    onClick={() => addNewItem(data.steps.length)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        marginTop: '20px' // Adjust as needed  
                                    }}
                                >
                                    <IoMdAddCircleOutline color="#0D4D3D" size={32} style={{ marginRight: '8px' }} />
                                    <span>Add Step</span>
                                </div>
                                <br></br><br></br><br></br>
                                <h4><GrTest /> Test Cadence: Settings</h4>
                                (Not visible to users, when testing your cadence above these are the bios that will be used for sellers and prospects)
                                <Card className="card-dashboard">
                                    <Card.Body>
                                        <Row>
                                            <React.Fragment>
                                                <Card.Title>Sample Seller Bio</Card.Title>
                                                <Form.Group>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={9}
                                                        maxLength={800} // Enforce character limit in the input  
                                                        value={data.sampleSellerBio}
                                                        onChange={handleSampleSellerBioChange}
                                                    />
                                                    <div className="form-tip">{sampleSellerBio.length}/800 characters</div> {/* Character counter */}
                                                </Form.Group>
                                            </React.Fragment>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="card-dashboard" style={{ marginTop: '20px' }}>
                                    <Card.Body>
                                        <Card.Title>Sample Prospect Bio</Card.Title>
                                        <div className="form-tip">Write in a bio of a target business that will be used in cadence testing above. End users do not see this, it's for your testing.</div>
                                        <Form.Group>
                                            <Form.Control
                                                as="textarea"
                                                rows={9}
                                                value={data.sampleProspectBio}
                                                onChange={handlesampleProspectBioChange}
                                                maxLength={700} // Enforce character limit in the input  
                                                placeholder="Enter your test bio (max 700 characters)"
                                            />
                                            <div className="form-tip">{sampleProspectBio.length}/700 characters</div> {/* Character counter */}
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br></br><br></br>
                                <button
                                    className={"selected-button"}
                                    type="submit"
                                    style={{ margin: '0 8px' }}
                                    onClick={onSave}>
                                    <FaSave /> Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        allstate: state.myReducer
    };
};

export default connect(mapStateToProps)(CadenceEditPage);