import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Badge, Button, Modal } from 'react-bootstrap';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, connect } from 'react-redux';
import { HiRefresh } from "react-icons/hi";
import { MdSmsFailed } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { refreshSubscriptions, refreshUser } from '../redux/actions';
import { fetchData } from '../helpers/api';
import Sidebar from '../components/Sidebar';

const BillingPage = (state) => {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
  const [monthly, setMonthly] = useState(false);
  const [annualPlan, setAnnualPlan] = useState(false);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [isBaseMonthly, setIsBaseMonthly] = useState(false);
  const [isBaseAnnual, setIsBaseAnnual] = useState(false);
  const [isBDRMonthly, setIsBDRMonthly] = useState(false);
  const [isBDRAnnual, setIsBDRAnnual] = useState(false);
  const [isMarketerMonthly, setIsMarketerMonthly] = useState(false);
  const [isMarketerAnnual, setIsMarketerAnnual] = useState(false);

  const handleToggle = (cycle) => {
    setBillingCycle(cycle);
  };


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const success = query.get('success');

  useEffect(() => {
    if (success === 'true') {
      setWaiting(true);
    } else if (success === 'false') {
      setWaiting(false);
    }
  }, [success]);

  useEffect(() => {
    if (state.state.subscriptions) {
      setIsBaseMonthly(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_BASE_MONTHLY));
      setIsBaseAnnual(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_BASE_ANNUAL));
      setIsBDRMonthly(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_BDR_MONTHLY));
      setIsBDRAnnual(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_BDR_ANNUAL));
      setIsMarketerMonthly(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_MARKETER_MONTHLY));
      setIsMarketerAnnual(state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === process.env.REACT_APP_SUB_MARKETER_ANNUAL));
    }
  }, [state.state.subscriptions]);


  const closeDialogue = () => {
    setWaiting(null);
  };


  const handleRefreshUserProfile = async () => {
    setIsRefreshing(true);
    try {
      let url = process.env.REACT_APP_WFE_URL + "/database/finduserbyid"
      const user = await fetchData(url, {
        userId: state.state.user.id
      }, {
        jwtToken: state.state.user.jwt
      });
      // console.log(JSON.stringify(user))
      dispatch(refreshUser(user));
      setIsRefreshing(false);
    } catch (error) {
      console.error("Error during handleRefreshUserProfile:", error);
      setIsRefreshing(false);
    }
  };


  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      let url = process.env.REACT_APP_WFE_URL + "/database/refreshsubscriptions"
      const subs = await fetchData(url, {
        email: state.state.user.email
      }, {
        jwtToken: state.state.user.jwt
      });
      console.log(JSON.stringify(subs))
      dispatch(refreshSubscriptions(subs.subscriptions));
      setIsRefreshing(false);
    } catch (error) {
      console.error("Error during handleRefresh:", error);
      setIsRefreshing(false);
    }
  };

  const handleSubscription = async (priceId) => {
    console.log("handlesub");
    try {
      let url = process.env.REACT_APP_WFE_URL + "/stripe/create-checkout-session"
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "stripeCustomerId": state.state.user.stripeCustomerId, "priceId": priceId }),
      });
      const body = await response.json()
      window.location.href = body.url
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  };

  const PaymentFailed = () => (
    // Used to block the screen when async jobs are running  
    <React.Fragment>
      <div className="spinner-overlay">
        <div className="tick-container">
          <div>
            <MdSmsFailed className="tick-icon" />
          </div>
          <span className="tick-text">Purchase Cancelled</span>
          <br></br><br></br>
          <Button onClick={closeDialogue}>OK</Button>
        </div>
      </div>
    </React.Fragment>
  );

  const PaymentSuccess = () => (
    // Used to block the screen when async jobs are running  
    <React.Fragment>
      <div className="spinner-overlay">
        <div className="tick-container">
          <div>
            <TiTick className="tick-icon" />
          </div>
          <span className="tick-text">Purchase Succeeded</span>
          <div className="tick-text-sml">Your account balance will be updated in the next few minutes</div>
          <br></br><br></br>
          <Button onClick={closeDialogue}>OK</Button>
        </div>
      </div>
    </React.Fragment>
  );

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000); // Convert epoch to milliseconds  
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount / 100);
  };

  const handleCancelClick = (subscriptionId) => {
    setSubscriptionToCancel(subscriptionId);
    setShowCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setShowCancelDialog(false);
    setSubscriptionToCancel(null);
  };

  const handleConfirmCancel = async () => {
    if (subscriptionToCancel) {
      try {
        let url = process.env.REACT_APP_WFE_URL + "/stripe/cancel-subscription"
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriptionId: subscriptionToCancel }),
        });
        if (response.ok) {
          console.log('Subscription cancelled successfully');
          // Optionally refresh the subscriptions or perform other actions here  
        } else {
          console.error('Failed to cancel subscription');
        }
      } catch (error) {
        console.error('Error during subscription cancellation:', error);
      } finally {
        handleCloseCancelDialog();
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          {waiting === true && <PaymentSuccess />}
          {waiting === false && <PaymentFailed />}
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
            <MobileMenu />
          </div>
          <Row>

            <Col className="adminBackground">
              <h1>Account & Billing</h1>
              <Row>
                <Col><div className="custom-form-label">Your email: {state.state.user.email}</div>
                  <div className="custom-form-label">Your credit balance: {state.state.user.credits} credits 😇</div>
                  <span onClick={handleRefreshUserProfile} style={{ cursor: 'pointer' }}>
                  <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
                </Col>
              </Row>

              <br></br><br></br>

              <div className="component-card">
                <h3>Your subscriptions</h3>
                <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
                  <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
                <br /><br />
                <Row>
                  {(!state.state.subscriptions) ? (
                    <p>No active subscriptions found, try hitting refresh to re-sync.</p>
                  ) : (
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Renewal Date</th>
                          <th>Plan Amount</th>
                          <th>Plan Currency</th>
                          <th>Plan Interval</th>
                          <th>Reference Code</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.state.subscriptions.map((subscription, index) => (
                          <tr key={index}>
                            <td>
                              {subscription.status === 'active' ? (
                                <Badge bg="success">Active</Badge>
                              ) : (
                                <Badge bg="light" text="dark">{subscription.status}</Badge>
                              )}
                            </td>
                            <td>{formatDate(subscription.current_period_end)}</td>
                            <td>{formatCurrency(subscription.plan.amount, subscription.plan.currency)}</td>
                            <td>{subscription.plan.currency.toUpperCase()}</td>
                            <td>{subscription.plan.interval}</td>
                            <td>{subscription.id}</td>
                            <td>
                              {subscription.status !== 'canceled' && (
                                <Badge className="delete-badge" onClick={() => handleCancelClick(subscription.id)}><FaRegTrashAlt /> Cancel</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  <Col>
                    {/* Additional plans can be added here */}
                  </Col>
                </Row>
              </div>
              <Row>
                <p></p><p></p>
                <h3>Available plans</h3>
                <p>
                  Get two months free when you purchase an annual submission.
                </p>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="toggle-switch">
                <button
                  className={billingCycle === 'monthly' ? 'active' : ''}
                  onClick={() => handleToggle('monthly')}
                >
                  Monthly
                </button>
                <button
                  className={billingCycle === 'annual' ? 'active' : ''}
                  onClick={() => handleToggle('annual')}
                >
                  Yearly
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={4}>


              <Card className="card-dashboard">
                {isBaseMonthly ? <div><Badge bg="success">Active</Badge></div> : null}
                {isBaseAnnual ? <div><Badge bg="success">Active</Badge></div> : null}
                <h4>Base Plan</h4>
                <p className="price">
                  {billingCycle === 'monthly' ? '$19 / mo' : '$190 / yr'}
                </p>
                <ul>
                  <li>{billingCycle === 'monthly' ? '100' : '1200'} credits per {billingCycle === 'monthly' ? 'month' : 'year, applied upfront'}</li>
                  <li>Ideal for salespeople and ad-hoc research needs</li>
                  <li>Full access to all features</li>
                  <li>Support via email, Teams, Zoom, and Hangouts</li>
                </ul>
                {
                  (isBaseMonthly || isBaseAnnual) ? null : (
                    billingCycle === "annual" ? (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_BASE_ANNUAL)}
                      >
                        Subscribe
                      </button>
                    ) : (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_BASE_MONTHLY)}
                      >
                        Subscribe
                      </button>
                    )
                  )
                }
              </Card>
            </Col>
            <Col md={6} lg={6} xl={4}>
              <Card className="card-dashboard">
                {isBDRMonthly ? <div><Badge bg="success">Active</Badge></div> : null}
                {isBDRAnnual ? <div><Badge bg="success">Active</Badge></div> : null}
                <h4>BDR Plan</h4>
                <p className="price">
                  {billingCycle === 'monthly' ? '$49 / mo' : '$490 / yr'}
                </p>
                <ul>
                  <li>{billingCycle === 'monthly' ? '600' : '7,200'} credits per {billingCycle === 'monthly' ? 'month' : 'year, applied upfront'}</li>
                  <li>Perfect for sellers who prospect approx 30 customers daily</li>
                  <li>Full access to all features</li>
                  <li>Support via email, Teams, Zoom, and Hangouts</li>
                </ul>
                {
                  (isBDRMonthly || isBDRAnnual) ? null : (
                    billingCycle === "annual" ? (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_BDR_ANNUAL)}
                      >
                        Subscribe
                      </button>
                    ) : (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_BDR_MONTHLY)}
                      >
                        Subscribe
                      </button>
                    )
                  )
                }
              </Card>
            </Col>
            <Col md={6} lg={6} xl={4}>
              <Card className="card-dashboard">
                {isMarketerMonthly ? <div><Badge bg="success">Active</Badge></div> : null}
                {isMarketerAnnual ? <div><Badge bg="success">Active</Badge></div> : null}
                <h4>Marketer Plan</h4>
                <p className="price">
                  {billingCycle === 'monthly' ? '$149 / mo' : '$1,490 / yr'}
                </p>
                <ul>
                  <li>{billingCycle === 'monthly' ? '2,000' : '24,000'} credits per {billingCycle === 'monthly' ? 'month' : 'year, applied upfront'}</li>
                  <li>Tailored for marketers building targeted prospect lists for their sales teams</li>
                  <li>Full access to all features</li>
                  <li>Premium Support</li>
                </ul>
                {
                  (isMarketerMonthly || isMarketerAnnual) ? null : (
                    billingCycle === "annual" ? (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_MARKETER_ANNUAL)}
                      >
                        Subscribe
                      </button>
                    ) : (
                      <button
                        className="selected-button"
                        onClick={() => handleSubscription(process.env.REACT_APP_SUB_MARKETER_MONTHLY)}
                      >
                        Subscribe
                      </button>
                    )
                  )
                }
              </Card>
            </Col>
          </Row>

          <Modal show={showCancelDialog} onHide={handleCloseCancelDialog}>
            <Modal.Header closeButton>
              <Modal.Title>Cancel Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to cancel your subscription?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCancelDialog}>
                No, don't cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmCancel}>
                Yes, cancel my subscription
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(BillingPage);  
