import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Form, Badge } from 'react-bootstrap';
import { FaSave, FaPhone, FaLinkedin } from 'react-icons/fa';
import { MdOutlineMailOutline } from "react-icons/md";
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { refreshCadences, refreshEntireUser } from '../redux/actions';
import { fetchData } from '../helpers/api';

const CadenceViewPage = (allstate) => {
    const { id } = useParams(); // Get the id from the URL parameters  
    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const [data, setData] = useState({
        _id: id,
        name: "",
        userId: allstate.allstate.user.id,
        sampleProspectBio: "",
        sampleSellerBio: "",
        lastModified: Date.now(),
        steps: []
    });
    const [isMissingRequiredInfo, setIsMissingRequiredInfo] = useState(false)

    // New useEffect to preload the cost data  
    useEffect(() => {
        if (allstate.allstate.readOnlyCadences && allstate.allstate.readOnlyCadences.length > 0) {
            const matchedCadence = allstate.allstate.readOnlyCadences.find(cadence => cadence._id === id);
            if (matchedCadence) {
                setData({
                    ...matchedCadence
                });
            }
        }
    }, [allstate.allstate.readOnlyCadences, id]); // Dependency array  

    const setMessage = (stepid) => {
        // Iterate over appCadences  
        for (const cadence of allstate.allstate.user.appCadences) {
            // Check if cadenceId matches the provided id  
            if (cadence.cadenceId === id) {
                // If there's a match, iterate through the steps  
                for (const step of cadence.steps) {
                    // Check if stepid matches  
                    if (step.stepid === stepid) {
                        // Return the value if a match is found  
                        return step.value;
                    }
                }
            }
        }
        // Return a default message if no match is found  
        return "";//
    };

    const onSave = async (stepid) => {
        console.log("onsave called for stepid", stepid);
        const updatedValue = textareaRef.current.value; // Get the updated value from the textarea  

        // Create a new array of appCadences with the updated value  
        const updatedAppCadences = allstate.allstate.user.appCadences.map(cadence => {
            if (cadence.cadenceId === id) {
                const updatedSteps = cadence.steps.map(step => {
                    if (step.stepid === stepid) {
                        return {
                            ...step,
                            value: updatedValue, // Update the step's value  
                        };
                    }
                    return step; // Return unchanged step  
                });

                // If no step is matched, add a new step with the provided stepid and updatedValue
                const stepExists = cadence.steps.some(step => step.stepid === stepid);
                if (!stepExists) {
                    updatedSteps.push({
                        stepid: stepid,
                        value: updatedValue,
                    });
                }

                return {
                    ...cadence,
                    steps: updatedSteps,
                };
            }
            return cadence; // Return unchanged cadence  
        });

        // Create an updated user object with the new appCadences  
        const updatedUser = {
            ...allstate.allstate.user
        };
        updatedUser.appCadences = updatedAppCadences
        console.log(updatedUser)

        // Dispatch the refreshUser action with the updated user object  
        dispatch(refreshEntireUser(updatedUser));
        let url = process.env.REACT_APP_WFE_URL + "/database/updateuserappcadenceprefs"
        fetchData(url,updatedUser,{ "jwtToken": allstate.allstate.user.jwt })
    };


    return (
        <React.Fragment>
            <div className="container">
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className="main-content">
                    <Row>
                        <AdminHeader />
                    </Row>
                    <div className="col-md-3 d-block d-md-none">
                        <MobileMenu />
                    </div>
                    <Row>
                        <Col className="adminBackground">
                            <Row style={{ minHeight: '300px', margin: '6px' }}>
                                <h1>{data.name}</h1>
                                {data.youTubeUrl && (
                                    <><Col md={6}>
                                        <h5>{data.description}</h5>
                                        <br></br>
                                        <h6>Further info: <a href={`https://${data.furtherInfoUrl}`} target="_blank" rel="noopener noreferrer">{data.furtherInfoUrl}</a></h6>
                                    </Col>
                                        <Col md={6}>

                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/${data.youTubeUrl}`}
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerpolicy="strict-origin-when-cross-origin"
                                                allowfullscreen>
                                            </iframe>

                                        </Col>
                                    </>
                                )}
                            </Row>
                            <div>
                                {data.steps.map((item, index) => (
                                    <Card key={index} className="card-dashboard">
                                        <Card.Body>
                                            <Row>
                                                <React.Fragment>
                                                    <Col
                                                        xs={12}
                                                        style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontSize: '1.4em',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <span style={{ marginRight: '8px' }}>
                                                            {item.type === 'linkedin' ? (
                                                                <FaLinkedin color="#0D4D3D" size={26} />
                                                            ) : item.type === 'email' ? (
                                                                <MdOutlineMailOutline color="#0D4D3D" size={26} />
                                                            ) : (
                                                                <FaPhone color="#0D4D3D" size={26} />
                                                            )}
                                                        </span>
                                                        <span style={{ flex: 1 }}>
                                                            {item.name} (day {item.daysDelay})
                                                        </span>
                                                    </Col>
                                                </React.Fragment>
                                            </Row>

                                            <div>
                                                {item.requireUserContent && (
                                                    <>
                                                        <div className="divider"></div>
                                                        <Form.Group>
                                                            <Form.Label>{item.textBoxTitle}</Form.Label><Badge className="delete-badge">Required</Badge>
                                                            <div className="form-tip">{item.textTip}</div>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={9}
                                                                defaultValue={setMessage(item.stepid)} //initial value is what the user has saved previously
                                                                ref={textareaRef}
                                                            />
                                                        </Form.Group>
                                                        <br></br><br></br>
                                                        <button
                                                            className={"selected-button"}
                                                            type="submit"
                                                            style={{ margin: '0 8px' }}
                                                            onClick={() => onSave(item.stepid)}>
                                                            <FaSave /> Save
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))}

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    );
};

const mapStateToProps = (state) => {
    return {
        allstate: state.myReducer
    };
};

export default connect(mapStateToProps)(CadenceViewPage);