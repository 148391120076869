// Sidebar.js  
import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { IoIosHome, IoIosColorWand, IoIosCard } from "react-icons/io";
import { RiMenuSearchLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { TbWorldWww } from "react-icons/tb";
import { IoChatbubbleOutline } from "react-icons/io5";
import { LuWorkflow } from "react-icons/lu";
import logo from '../assets/images/Jummbo_PrimaryLogo_Forest.png'

const Sidebar = (state) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (

    <div className={`sidebar ${isOpen ? 'open' : ''}`}>

      <div className="hamburger" onClick={toggleSidebar}>
        <FaBars />
      </div>


      <Nav className="sidebar" defaultActiveKey="/dashboard">
        <img src={logo} alt="Logo" />
        <br></br>
        <NavLink to="/dashboard" activeClassName="active" className="sidebar-item">
          <IoIosHome className='icon' />
          Dashboard

        </NavLink>
        <NavLink to="/singlesite" activeClassName="active" className="sidebar-item">
          <TbWorldWww className='icon' />
          Prospect a URL
        </NavLink>
        <NavLink to="/listupload" activeClassName="active" className="sidebar-item">
          <FiUploadCloud className='icon' />
          Prospect a List
        </NavLink>
        <NavLink to="/allsearches" activeClassName="active" className="sidebar-item">
          <RiMenuSearchLine className='icon' />
          Create a List
        </NavLink>


        {state.state.readOnlyCadences && state.state.readOnlyCadences.length > 0 && (
          <>
            <div className="sidebar-section">CADENCES</div>
            {state.state.readOnlyCadences.map((cadence, index) => (
              <NavLink to={`/viewcadence/${cadence._id}`} activeClassName="active" className="sidebar-item" key={index}>
                <LuWorkflow className='icon' />
                {cadence.name}
              </NavLink>
            ))}
          </>
        )}

        <div className="sidebar-section">ADMIN</div>

        {state.state.cadences && state.state.cadences.length > 0 && (
          <NavLink to={`/cadence/${state.state.cadences[0]._id}`} activeClassName="active" className="sidebar-item">
            <IoIosColorWand className='icon' />
            Cadence Editor
          </NavLink>
        )}
        <NavLink to="/settings" activeClassName="active" className="sidebar-item">
          <IoMdSettings className='icon' />
          Settings
        </NavLink>
        <NavLink to="/billing" activeClassName="active" className="sidebar-item">
          <IoIosCard className='icon' />
          Billing
        </NavLink>
        <NavLink to="mailto:support@jummbo.ai" activeClassName="active" className="sidebar-item">
          <IoChatbubbleOutline className='icon' />
          Support
        </NavLink>
      </Nav>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(Sidebar);
