import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import singlesearchicon from '../assets/images/singlesearchicon.png';
import multisearchicon from '../assets/images/multiearchicon.png';
import listuploadicon from '../assets/images/listuploadicon.png';


const DashboardPage = () => {

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
            <MobileMenu />
          </div>
          <Row>
            <Col className="adminBackground">
              <h1>Dashboard</h1>
              <Row>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/singlesite" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">

                      <h4>Prospect a URL</h4>
                      Create all your prospecting assets.
                      <br></br><br></br>
                        <img src={singlesearchicon} alt="Single Search" className="img-fluid" />
                    </div>
                  </NavLink>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/listupload" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">
                      <h4>Prospect a List</h4>
                        Prospect your list of organisations.
                        <br></br><br></br>
                        <img src={listuploadicon} alt="List Upload" className="img-fluid" />
                        <br></br>
                    </div>
                  </NavLink>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <NavLink to="/allsearches" style={{ textDecoration: 'none' }}>
                    <div className="card-dashboard">
                      <h4>Create a List</h4>
                        Find and shortlist hundreds of Google results in a flash.
                        <br></br><br></br>
                        <img src={multisearchicon} alt="Multi Search" className="img-fluid" />


                    </div>
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;