import React, { useState } from 'react';
import logo from '../assets/images/Jummbo_Multiplied_10_Orange.png';

const Footer = () => {  
  const currentYear = new Date().getFullYear(); // Get the current year  
  const [isMinimized, setIsMinimized] = useState(true);

  const toggleChatbot = () => {
    setIsMinimized(!isMinimized);
  };

  return (  
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>  
      <br></br>
      <br></br>
      <img src={logo} alt="Jummbo_Multiplied_10_Orange" style={{ width: '100%', maxWidth: '400px' }} />  
      <br></br>
      <div className="footer-ack">support@jummbo.ai</div>
      <div className="footer-ack">© Sudo Online Pty Ltd {currentYear}, release 1.46</div>
      <br></br>
      {/* <div style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '300px',
        height: isMinimized ? '40px' : '80vh',
        transition: 'height 0.3s ease',
        zIndex: 1000,
        border: '2px solid #d46728',
        borderRadius: '17px 0 0 0',
      }}>
        <button onClick={toggleChatbot} style={{
          width: '100%',
          height: '40px',
          fontWeight: 'bold',
          backgroundColor: '#B8D7F1',
          color: '#d46728',
          cursor: 'pointer',
          borderRadius: '15px 0 0 0',
          border: '1px solid #d46728',
        }}>
          {isMinimized ? 'Open Chat' : 'Close Chat'}
        </button>
        {!isMinimized && (
          <iframe 
            src="https://copilotstudio.microsoft.com/environments/Default-4a85d444-1bd4-49c3-ae10-4ffd87176242/bots/cr639_marketingDocumentsAssistant/webchat?__version__=2" 
            frameBorder="0" 
            style={{ width: '100%', height: 'calc(100vh - 40px)' }} 
          />
        )}
      </div> */}
    </div>  
  );  
};  

export default Footer;
