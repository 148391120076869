import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { refreshAllSearches, updateUserCredits } from '../redux/actions';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import SearchProgress from '../components/SearchProgress';
import { fetchData } from '../helpers/api';

const SearchStep2Page = (state) => {
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch();
  const [searchTopic, setSearchTopic] = useState(state.state.searches.find(search => search._id === id).SearchQuery.topic);
  const [selectedRecords, setSelectedRecords] = useState('100');


  const onSearchSubmit = async (e) => {
    e.preventDefault();
    console.log('Search ID:', id);
    console.log('Selected Records:', selectedRecords);
    console.log('Search Topic:', searchTopic);

    //update credit balance
    //deductCreditValue = round up selectedRecords/10 to nearest integer
    let deductCreditValue = Math.ceil(selectedRecords / 10)
    let newCredits = state.state.user.credits - deductCreditValue
    await dispatch(updateUserCredits(newCredits))
    let url = process.env.REACT_APP_WFE_URL + "/database/updateuser"
    fetchData(url, { "_id": state.state.user.id, "credits": newCredits }, { "jwtToken": state.state.user.jwt })

    try {
      const updatedSearches = await Promise.all(state.state.searches.map(async (search) => {
        if (search._id === id) {
          const updatedSearch = {
            ...search,
            SearchQuery: {
              topic: searchTopic,
              keywords: search.SearchQuery.keywords,
              locations: search.SearchQuery.locations,
              searchregion: search.SearchQuery.searchregion,
              selectedkeywords: search.SearchQuery.selectedkeywords,
              selectedlocations: search.SearchQuery.selectedlocations
            },
            progress: {
              status: "Submitted",
              numRows: selectedRecords,
            }
          };
          let url = process.env.REACT_APP_WFE_URL + "/bulksearches/update"
          fetchData(url, updatedSearch, state.state.user.jwt)
          return updatedSearch;
        }
        // Return the search as-is if it doesn't match the ID to send to the reducer  
        return search;
      }));
      dispatch(refreshAllSearches(updatedSearches));
      navigate(`/allsearches`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    < React.Fragment >
      <div className="container">
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <Row>
            <Col className="adminBackground">
              <SearchProgress id={id} stage='2' />
              <Container>
                <Row>
                  <Col>
                    {
                      state.state.searches.map((search, index) => (
                        <React.Fragment>

                          {search._id === id ? (
                            <React.Fragment>

                              <div className="component-card">
                                <h6>Your Search:</h6>
                                <h4>{search.SearchQuery.topic}</h4>

                                <Row className="mt-4">
                                  <Form onSubmit={onSearchSubmit}>
                                    <Form.Group>
                                      <Form.Label>Search Results Required</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={selectedRecords}
                                        onChange={(e) => setSelectedRecords(e.target.value)}
                                      >
                                        <option value="50">50 records (5 credits)</option>
                                        <option value="100">100 records (10 credits)</option>
                                        <option value="250">250 records (25 credits)</option>
                                        <option value="500">500 records (50 credits)</option>
                                        <option value="1000">1000 records (100 credits)</option>
                                        <option value="2000">2000 records (200 credits)</option>
                                      </Form.Control>
                                    </Form.Group>
                                    <br></br>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Shortlisting Criteria</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter criteria"
                                        defaultValue={search.SearchQuery.topic}
                                        onChange={(e) => setSearchTopic(e.target.value)}
                                      />
                                    </Form.Group>
                                    <div>

                                    </div>

                                    <button
                                      className={
                                        (selectedRecords / 10) > state.state.user.credits
                                          ? "unselected-button"
                                          : "selected-button"
                                      }
                                      type="submit"
                                      disabled={(selectedRecords / 10) > state.state.user.credits}
                                    >
                                      {'Submit Bulk Search'}
                                    </button>


                                  </Form>

                                </Row>
                              </div>
                            </React.Fragment>
                          ) : (null)}
                        </React.Fragment>
                      ))
                    }
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  );
};



const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(SearchStep2Page);